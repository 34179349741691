<template>
  <form @submit.prevent="add">
    <NuxtLink
      :to="`/shop/product/${data?.url}`"
      class="flex flex-col relative h-full"
    >
      <div class="w-full h-[167px] overflow-hidden bg-white p-3 shrink-0">
        <img
          :src="data.images[0].url"
          :alt="data.name"
          class="object-contain w-full h-full"
          width="400px"
          height="320"
        >
        <!-- <button @click.prevent class="absolute top-3 right-3">
          <fa class="text-2xl" icon="fa-regular fa-heart"/>
        </button> -->
      </div>
      <div
        class="text-grey-500 text-xs sm:text-sm lg:text-base font-bold mb-3 border-2 p-1 text-center"
        :style="{ backgroundColor: data.primaryColor, borderColor: data.primaryColor }"
      >
        {{ data.name }}
      </div>
      <div class="mb-4" v-html="data.shortDescription" />
      <div class="mb-4">
        <div v-for="input in data.customFields">
          <FormSelect
            v-if="input.type === 'select'"
            @click.prevent
            :label="input.label"
            v-model="input.value"
            :options="input.options"
            :required="input.required"
          />
        </div>
      </div>
      <div v-if="featured" class="flex items-end gap-5 mb-10 justify-between mt-auto">
        <ProductPrice :price="featuredProduct.price" :product="featuredProduct" />
        <span class="ml-auto">
          {{ featuredProduct.size }}
          <span v-if="featuredProduct.size">{{ featuredProduct.measure }}</span>
        </span>
      </div>
      <div v-else class="flex items-end gap-5 mb-10 justify-between mt-auto">
        <ProductPrice :price="selectedProduct.price" :product="selectedProduct" />
        <FormSelect
          v-if="options.length > 1"
          @click.prevent
          class="!w-2/5 ml-auto"
          label="Об `єм"
          v-model="option"
          :options="options"
        />
        <span v-else class="ml-auto">
          {{ selectedProduct.size }}
          <span v-if="selectedProduct.size">{{ selectedProduct.measure }}</span>
        </span>
      </div>
      <div>
        <FormBtn v-if="selectedProduct.isAvailable" class="uppercase" @click.stop type="submit">Купити</FormBtn>
        <div v-else class="text-center">Немає в наявності</div>
        <!-- <button @click.prevent="add(data)" class="px-4 bg-[#f5d3d2] h-full">
          <fa icon="fa-solid fa-cart-plus" />
        </button> -->
      </div>
    </NuxtLink>
  </form>
</template>

<script setup>
import { useCartStore } from '@/stores/cart'
const { showSuccess } = useAlerts()

const props = defineProps({
  data: {
    type: Object,
    reqired: true
  },
  featured: {
    type: Boolean,
    default: false
  }
})

const cartStore = useCartStore()
const option = ref(props.data.variants[0].size)
const selectedProduct = computed(() => props.data.variants.find((item) => item.size === option.value))
const options = computed(() => props.data.variants.map((variant) => ({ id: variant.size, name: `${variant.size} ${selectedProduct.value.measure}` })))

const add = () => {
  cartStore.addToCart({
    selectedProduct: props.featured ? featuredProduct.value : selectedProduct.value,
    _id: props.data.id,
    ...props.data
  })
  showSuccess(`${props.data.name} додали до кошику`)
}

const featuredProduct = computed(() => props.data.variants.find((item) => props.data.featured && item.size === props.data.featuredSize ))

</script>
